import React from 'react';
import './App.css';
import { Linkedin, Twitter, Github, Mail } from 'lucide-react';
import { HashRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

function Layout({ content }: { content: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="py-16 max-w-2xl mx-auto min-h-screen flex flex-col">
        <div className="font-semibold text-lg pb-1 cursor-pointer hover:underline" onClick={() => navigate('/')}>Jet New</div>
        <hr />
        <div className="pt-4 flex space-x-12">
          <div className="flex flex-col space-y-1">
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/about')}>About</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/services')}>Services</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/projects')}>Projects</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/contact')}>Contact</div>
          </div>
          {content}
        </div>
        <div className="flex space-x-2 mt-auto">
          <a href="https://www.linkedin.com/in/jetnew" target="_blank" rel="noopener noreferrer">
            <Linkedin />
          </a>
          <a href="https://twitter.com/jetnew_sg" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>
          <a href="https://github.com/jetnew" target="_blank" rel="noopener noreferrer">
            <Github />
          </a>
          <a href="mailto:jet@aetherlab.ai" target="_blank" rel="noopener noreferrer">
            <Mail />
          </a>
        </div>
      </div>
    </>
  )
}

function Home() {
  return (
    <Layout content={
      <div className="space-y-4">
        <p>Hi! I'm an AI engineer based in 🇸🇬, working on agents and knowledge graphs.</p>
        <p>I'm currently building <a href="https://atlas.aetherlab.ai" className="cursor-pointer underline hover:italic">Atlas</a>, a browser assistant that helps people understand complex content better.</p>
        <p>Atlas is born out of an observation: While everyone recognizes the potential of LLMs, most don't know how to leverage its full capabilities.</p>
        <p>The AI apps of tomorrow should strive towards not just novel but useful and intuitive interfaces to avoid being newfangled.</p>
        <p>LLMs can help us learn things, do things and create things. <a href="https://atlas.aetherlab.ai" className="cursor-pointer underline hover:italic">Atlas</a> is my thesis to help us learn better: by enriching our understanding of content.</p>
      </div>
    } />
  )
}

function About() {
  return (
    <Layout content={
      <div className="space-y-4">
        <div>
          <div>Founder, AI Engineer</div>
          <p>Atlas, Jun 2024 - Present</p>
        </div>
        <div>
          <div>Co-Founder, AI Engineer</div>
          <p>AgentScale AI, Jun 2023 - May 2024</p>
        </div>
        <div>
          <div>Data Scientist</div>
          <p>Indeed, May 2022 - Jul 2022</p>
        </div>
        <div>
          <div>AI Researcher</div>
          <p>National University of Singapore, Nov 2020 - Apr 2022</p>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p>Grab, May 2020 - Jul 2020</p>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p>Infocomm Media Development Authority, Nov 2018 - Jun 2019</p>
        </div>
      </div>
    } />
  )
}

function Services() {
  return (
    <Layout content={
      <>
        <div className="space-y-4">
          <div>
            <div>LLM App Development</div>
            <ul className="list-disc pl-5">
              <li>Develop a large language model (LLM) application for your startup.</li>
              <li>Examples: chatbots, newsletters, question-answering assistants</li>
            </ul>
          </div>
          <div>
            <div>Web Automation Development</div>
            <ul className="list-disc pl-5">
              <li>Automate web tasks with robotic process automation (RPA), large language models (LLM) and browser automation.</li>
              <li>Examples: web scraping, cron jobs, browser extensions</li>
            </ul>
          </div>
        </div>
      </>
    } />
  )
}

function Projects() {
  return (
    <Layout content={
      <>
        <div className="space-y-4">
          <div>
            <div>Layla, AI Sales Rep</div>
            <ul className="list-disc pl-5">
              <li>Built autonomous AI research agents to qualify B2B leads to ICP and hyper-personalize cold emails with industry best practices to maximize reply rates.</li>
              <li>Developed LLM capabilities: RAG, browser agents/RPA, prompt engineering, performance evaluation, quality/latency/cost optimization, knowledge graphs</li>
              <li>Deployed full-stack app: React, Shadcn, Tailwind, FastAPI, Render, Supabase/Auth, Playwright, Stripe, Chrome API</li>
            </ul>
          </div>
          <div>
            <div>ArXiv Terminal</div>
            <ul className="list-disc pl-5">
              <li>Built daily recurring web scraper to filter research papers by boolean queries to prevent missing the latest state-of-the-art papers.</li>
              <li>Developed web scraper: Python requests, Playwright</li>
              <li>Deployed Telegram bot: Telegram, Modal, Supabase</li>
            </ul>
          </div>
        </div>
      </>
    } />
  )
}

function Contact() {
  return (
    <Layout content={
      <>
        <p>Email me at <a href="mailto:jet@aetherlab.ai" className="hover:underline">jet@aetherlab.ai</a>.</p>
      </>
    } />
  )
}

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
